import React from "react";
import { createRoot } from 'react-dom/client';

import {Center, ChakraProvider, Container, Text, Textarea} from "@chakra-ui/react";

function App() {
    const [value, setValue] = React.useState('');
    const handleInputChange = (e) => setValue(e.target.value);
    const wordsLeft = 25 - value.split(/\s+/).filter(e => e).length;
    let msg;
    if (wordsLeft > 0) {
        msg = <><span style={{color: 'green'}}>{wordsLeft}</span> left</>;
    } else if (wordsLeft < 0) {
        msg = <><span style={{color: 'red'}}>{Math.abs(wordsLeft)}</span> too many</>;
    } else {
        msg = <>Bam!</>;
    }
    return (
        <ChakraProvider>
            <Center>
                <Container>
                    <Textarea
                        value={value}
                        onChange={handleInputChange}
                        marginBottom="10px"
                        height="9em"
                        marginTop="10px"
                        autoFocus
                    />
                    <Text>
                        {msg}
                    </Text>
                </Container>
            </Center>
        </ChakraProvider>
    )
}

const root = createRoot(document.getElementById('app'));
root.render(<App/>);
